import { createSlice } from '@reduxjs/toolkit'
const initialStateValues = {
    item: {
        redeemedItems:[],
        redeemedCategory:[],
        loyalityPoints:0,
        tempLoyalityPoints:0,
        rewardedOrder:{isReward:false, reward_size: null ,search: null, name:null, isCategory:null,reward_id:null}
    },
    kidsComboItem: {
        isAllThreeItemAdded: false
    },
    startedCheckoutBrazeEvent: {
        isPending: false,
        triggerEvent: false,
        isSent: false
    },
    LocationFlow:{
        isFlowActive: false,
        locationModalOpen: false,
        intendedPath: null
    }
}

export const itemSlice = createSlice({
    name: 'item',
    initialState: initialStateValues,
    reducers: {
        updateLoyalityPoints:(state,action)=>{
            state.item.loyalityPoints=action.payload;
        },
        updateTempLoyalityPoints:(state,action)=>{
            state.item.tempLoyalityPoints=action.payload;
        },
        updateRedeemedOrderContext: (state,action) =>{
            const {isReward, reward_size, search, name, isCategory,reward_id} = action.payload;
            state.item.rewardedOrder = {isReward, reward_size ,search, name, isCategory,reward_id}
        },
        updateAllItemAddedStateForKidsCombo: (state, action) => {
            const {allItemSelectionState} = action.payload;
            state.kidsComboItem.isAllThreeItemAdded= allItemSelectionState;
        },
        resetRedeemedOrderContex:(state) => {
            state.item.rewardedOrder = {isReward:false, reward_size: null ,search: null, name: null, isCategory:null,reward_id:null}
        },
        updateStartedCheckoutBrazeEvent : (state, action) => {
            state.startedCheckoutBrazeEvent.isPending = action.payload?.isPending ?? false
            state.startedCheckoutBrazeEvent.triggerEvent = action.payload?.triggerEvent ?? false
            state.startedCheckoutBrazeEvent.isSent = action.payload?.isSent ?? false
        },
        startLocationFlow : (state, action) => {
            state.LocationFlow.isFlowActive = true;
            state.LocationFlow.locationModalOpen = true;
            state.LocationFlow.intendedPath = action.payload;
        },
        startLocationFlowModalClose : (state) => {
            state.LocationFlow.locationModalOpen = false;
        },
        resetLocationFlow : (state) => {
            state.LocationFlow.isFlowActive = false;
            state.LocationFlow.locationModalOpen = false;
            state.LocationFlow.intendedPath = null;
        },
        resetRedeemedItemContext:(state) => {
            state.item.redeemedItems = [];
            state.item.redeemedCategory = [];
            state.item.loyalityPoints = 0;
            state.item.tempLoyalityPoints=0;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateLoyalityPoints, resetRedeemedItemContext,updateTempLoyalityPoints, updateRedeemedOrderContext, resetRedeemedOrderContex, updateAllItemAddedStateForKidsCombo, updateStartedCheckoutBrazeEvent, startLocationFlow, resetLocationFlow, startLocationFlowModalClose } = itemSlice.actions


export const itemSliceReducer = itemSlice.reducer;


