import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../Buttons/Button';
import { useHistory } from 'react-router-dom';
import { useStoreLocation } from '../../../context/StoreLocation';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';
import { startLocationFlow } from '../../../redux/slices/itemSlice';
import {
  CURRENT_USER,
  CUSTOMER_MICRO_SERVICE,
  ISSUE_OFFER,
  RESEND_EMAIL_VERIFICATION,
  ROUTE_MENU,
  ROUTE_ORDER,
  UPDATE_EMAIL,
} from '../../../constants';

import styleClasses from './inAppMessage.module.scss';
import {
  logInAppMessageButtonClick,
  logInAppMessageClick,
  logInAppMessageImpression,
} from '@braze/web-sdk';
import ValidateEmailModal from './ValidateEmailModal';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import ResendEmailModal from './ResendEmailModal';
import { getUser, getVisitorId } from '../../../helpers/helperMethods';
import { brazeLogCustomEventLoyaltyAccountCreated } from '../../../helpers/brazeHelper';
import { useAuthState } from '../../../context/UserAuthentication';
import { setShowCart } from '../../../redux/slices/cartSlice';
import axios from 'axios';
import { Toast_Func } from '../../../helpers/toast.helper';

export default function InAppMessage(props) {
  const { inAppMessage, closeModal, inAppMessageState } = props;

  const { dispatch: contextDispatch } = useAuthState();

  const isItMobile = useCheckMobileScreen();

  const [resendEmail, setResendEmail] = useState(false);
  const [showValidateEmail, setShowValidateEmail] = useState(
    inAppMessageState === UPDATE_EMAIL
  );

  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [resendEmailBtnText, setResendEmailBtnText] = useState(
    RESEND_EMAIL_VERIFICATION
  );
  const [resendEmailData, setResendEmailData] = useState();
  const [resendEmailPayload, setResendEmailPayload] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const initialFormStateForEmailVarification = {
    email: '',
    confirm_email: '',
  };

  const { locationInfo } = useStoreLocation();
  const dispatch = useAppDispatch();
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected
  );

  const openLocationModal = () => {
    const event = new CustomEvent('openLocationSelectionModal');
    window.dispatchEvent(event);
  };

  const history = useHistory();

  const { extras } = inAppMessage;

  const signInUserWithoutNewAPICall = (response) => {
    setShowValidateEmail(false);
    setResendEmail(false);
    contextDispatch({
      type: 'LOGIN_SUCCESS',
      payload: response.data.data.customer,
    });
    localStorage.setItem(
      CURRENT_USER,
      JSON.stringify({
        ...response.data.data.customer,
        visitor_id: getVisitorId(),
      })
    );
    if (response.data.data.customer.is_new_user)
      brazeLogCustomEventLoyaltyAccountCreated(response.data.data.customer);
    dispatch(setShowCart(false));
    history.push(ROUTE_MENU);
  };

  const issueOffer = async () => {
    setButtonLoading(true);
    try {
      const user = getUser();
      const payload = {
        ...extras,
        encoded_cz_id: user.encoded_cz_id,
        source: isItMobile ? 'Mobile Web' : 'Web',
        marketing_channel: 'in_app',
      };
      let response = await axios.post(
        `${CUSTOMER_MICRO_SERVICE}/loyalty/campaign-trigger`,
        payload
      );
      if (response?.data?.data?.show_toast) {
        Toast_Func({
          status: true,
          message: response?.data?.data?.message,
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const handleRedirection = async (e) => {
    if (extras?.action === ISSUE_OFFER) {
      await issueOffer();
    }
    // Log events and close modal first (these happen in all cases)
    logInAppMessageClick(inAppMessage);
    logInAppMessageButtonClick(inAppMessage);

    closeModal(e);

    // Handle item-specific redirects
    if (extras?.item_id && extras?.category_id) {
      const itemPath = {
        pathname: `${ROUTE_ORDER}/${extras.item_id}`,
        search: `category_id=${extras.category_id}`,
      };

      // Check if location is selected
      if (isOrderTypeSelected && locationInfo.selectedStore) {
        history.push(itemPath);
      } else {
        dispatch(startLocationFlow(itemPath));
        openLocationModal();
      }
      return;
    }

    if (!extras.redirect_to) return;

    // Handle general redirects
    history.push(extras.redirect_to);
  };

  const handleEmailVerificationFormSubmission = async (
    values,
    { setSubmitting, setErrors }
  ) => {
    try {
      const user = getUser();
      let payload = {
        ...resendEmailPayload,
        external_email: values.email,
        action: UPDATE_EMAIL,
        is_email_verified: 0,
        loyalty_user_id: user.loyalty_user_id,
        encoded_cz_id: user.encoded_cz_id,
        source: isItMobile ? 'Mobile Web' : 'Web',
      };
      setResendEmailPayload(payload);
      let response = await axios.post(
        `${CUSTOMER_MICRO_SERVICE}/loyalty/campaign-trigger`,
        payload
      );
      if (response?.data?.data?.code == 200) {
        setShowValidateEmail(false);
      }
      if (response?.data?.data?.code == 200 && response?.data?.data?.customer) {
        signInUserWithoutNewAPICall(response);
        Toast_Func({
          status: true,
          message: response?.data?.data?.message,
        });
      } else if (response?.data?.data?.is_email_sent) {
        setResendEmailData(response?.data?.data);
        setShowValidateEmail(false);
        setResendEmail(true);
      } else if (response?.data?.data?.code == 200) {
        Toast_Func({
          status: true,
          message: response?.data?.data?.message,
        });
      }
    } catch (error) {
      if(error?.response?.data?.errors?.[0]) {
        setErrors({ email: error?.response?.data?.errors?.[0], confirm_email: error?.response?.data?.errors?.[0] });
      }
    }
  };

  const handleModalClose = (e) => {
    // Log events and close modal first (these happen in all cases)
    logInAppMessageClick(inAppMessage);
    closeModal(e);
  };

  return (
    <>
      {inAppMessageState === UPDATE_EMAIL ? (
        <>
          {showValidateEmail && !resendEmail && (
            <ValidateEmailModal
              showValidateEmail={showValidateEmail}
              setShowValidateEmail={setShowValidateEmail}
              initialFormStateForEmailVarification={
                initialFormStateForEmailVarification
              }
              handleEmailVerificationFormSubmission={
                handleEmailVerificationFormSubmission
              }
              isItMobile={isItMobile}
              addDarkerOverlay={true}
            />
          )}
          {!showValidateEmail && resendEmail && (
            <ResendEmailModal
              resendEmail={resendEmail}
              setResendEmail={setResendEmail}
              setShowValidateEmail={setShowValidateEmail}
              resendEmailPayload={resendEmailPayload}
              sendingEmail={sendingEmail}
              setSendingEmail={setSendingEmail}
              setResendEmailData={setResendEmailData}
              resendEmailData={resendEmailData}
              isItMobile={isItMobile}
              setResendEmailBtnText={setResendEmailBtnText}
              resendEmailBtnText={resendEmailBtnText}
              signInUserWithoutNewAPICall={signInUserWithoutNewAPICall}
              addDarkerOverlay={true}
              useCampaignAPI={true}
            />
          )}
        </>
      ) : (
        <Modal
          show={inAppMessage}
          centered
          className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
          onHide={handleModalClose}
          size="sm"
        >
          <Modal.Header className="justify-content-between p-0">
            <img src={extras?.image_url} className={styleClasses.topImage} />
            <button
              type="button"
              className="btn modal-close-button pe-0 blue-icon fw-bolder"
              onClick={handleModalClose}
            >
              Close
            </button>
          </Modal.Header>
          <Modal.Body className="px-3 gap-40">
            <>
              <p
                className="f-s20 font-Cls text-center fw-bolder lh-normal mb-2 text-capitalize"
                style={{ whiteSpace: 'pre-line' }}
              >
                <span>{extras?.header}</span>
              </p>
              <div className="text_center">
                <div className="mt-2 text-center mb-4 font-Visby-cf">
                  <span style={{ whiteSpace: 'pre-line' }}>{extras?.body}</span>
                </div>
                <Button
                  type="button"
                  className="btn btn-large mb-sm-3 mb-3 mt-sm-3 mt-md-4 d-block w-100"
                  onClick={handleRedirection}
                  loading={buttonLoading}
                  disabled={buttonLoading}
                >
                  {extras?.button_title}
                </Button>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
