import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import NewFooter from "../../components/Footer/NewFooter";
import * as CONSTANT from "../../constants";
import { useAuthState } from "../../context/UserAuthentication";
import { ForgotPasswordFormFields } from "../../models/forms.model";
import styleClasses from "./accounts.module.scss";
import ForgotPasswordForm from "../../components/ResetPassword/ForgotPasswordForm";
import { customerBusinessService } from "../../services";
import { Toast_Func } from "../../helpers/toast.helper";

interface IForgotPassword {
  isGuestModal? : boolean
}
const ForgotPassword : React.FC<IForgotPassword> = ({isGuestModal = false}) => {
  const [emailNotSent, setEmailNotSent] = useState(true);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState<string>("");
  const [invalidRecaptchaMessage, setInvalidRecaptchaMessage] = useState<string>("");
  // const initialFormState: ForgotPasswordFormFields = {
  //   email: "",
  // };
  const handleInvalidEmailMessage = () => {
    setInvalidEmailMessage("");
    setInvalidRecaptchaMessage("");
  }
  const { authInfo } = useAuthState();
  const history = useHistory();
  const recaptchaRef = useRef(null);

  const handleFormSubmission = async (
    values: ForgotPasswordFormFields,
  ) => {
    console.log(recaptchaRef)
    const recaptchaValue = recaptchaRef?.current?.getValue();
    if(!recaptchaValue) {
      setInvalidRecaptchaMessage("Please complete the reCAPTCHA before submitting.");
      return;
    }
    try {
      const response = await customerBusinessService.forgotPassword({
        email: values.email,
        recaptcha_token: recaptchaValue,
        platform: "web",
      })
      setEmailNotSent(false);
    } catch (error) {
      Toast_Func({ status: false, message: error?.response?.data?.message });
      recaptchaRef.current.reset();
      setInvalidEmailMessage(error.response.data?.errors?.[0]);
    }
  };

  /* If the signed-in customer tries to acess Forgot Password Page he will be redirected to Menu Page */
  useEffect(() => {
    authInfo.userId && authInfo.type !== CONSTANT.GUEST_USER && history.push(CONSTANT.ROUTE_MENU);
  }, []);

  return (
    <>
      <Container fluid className="pt-156">
        <Row>
          {
            !isGuestModal &&
              <Col
                  md="6"
                  className={`${styleClasses.welcome_banner_wrap} p-0 d-none d-md-block`}
              >
                <div>
                  <img
                      src={require("../../images/accountBanner.png")}
                      className="img-fluid"
                      alt="account banner"
                  />
                </div>
              </Col>
          }
          <Col md= {isGuestModal ? '12' : '6'} className="position-relative">
            {
                !isGuestModal &&
                <div className="text-start absolute-back-btn">
                  <Link
                      to={CONSTANT.ROUTE_WELCOME_SCREEN}
                      className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0"
                  >
                    Back
                  </Link>
                </div>
            }
            <div
              className={`${styleClasses.account_content_wrapper} new_form_design px-md-3 pt-4  pb-5 pb-md-0`}
            >
              {
                !isGuestModal &&
                  <div className="text-start d-md-none mb-3">
                    <Link
                        to={CONSTANT.ROUTE_WELCOME_SCREEN}
                        className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0"
                    >
                      Back
                    </Link>
                  </div>
              }
              <h1
                className={`font-Cls h-48 f-s32 f-sm-s22 text-md-center ${
                  emailNotSent ? "mb-4" : "mb-2 text-center f-sm-s48"
                }`}
              >
                {emailNotSent ? "Forgot Password" : "Sent!"}
              </h1>
              {emailNotSent ? (
                <ForgotPasswordForm 
                  handleFormSubmission={handleFormSubmission} 
                  invalidEmailMessage={invalidEmailMessage} 
                  handleInvalidEmailMessage={handleInvalidEmailMessage}
                  recaptchaRef={recaptchaRef}
                  invalidRecaptchaMessage={invalidRecaptchaMessage}
                />
              ) : (
                <>
                  <p className="p-16 text-center">
                    Check your inbox and follow the steps to create a new
                    password.
                  </p>
                  <hr className="my-4 d-md-none" />
                  {
                    !isGuestModal &&
                      <div className="max-content mx-auto">
                        <Link
                            to={CONSTANT.ROUTE_LOGIN}
                            className="text-decoration-none btn btn-large d-block"
                        >
                          SIGN IN
                        </Link>
                      </div>
                  }
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {
        !isGuestModal &&
          <div>
            <NewFooter sectionController="pt-lg-5 pt-0" textColor="color-yellow" />
          </div>
      }
    </>
  );
};
export default ForgotPassword;
