import React, { useEffect } from 'react';
import { checkPushStatus, showSoftPushPrompt } from '../helpers/brazeHelper';
import { useAuthState } from '../context/UserAuthentication';
import { GUEST_USER } from '../constants';
import { requestPushPermission } from '@braze/web-sdk';
import { isUserAuthorizedForPush } from './utils';
import { getUser } from '../helpers/helperMethods';

export const PushNotificationPrompt = ({ onClose }) => {
  const { authInfo } = useAuthState();

  useEffect(() => {
    //   // Only show the prompt if push is not already enabled
    const checkStatus = async () => {
      const notifications = localStorage.getItem('notifications');
      const { pushSupported, isPushEnabled } = checkPushStatus();
      if (pushSupported && !isPushEnabled && notifications !== 'false') {
        showSoftPushPrompt().then((granted) => {
          onClose && onClose(granted);
        });
      }
      if (pushSupported && Notification.permission === 'granted') {
        requestPushPermission((granted) => {
          if (granted) {
            console.log('Re-registering push token after sign-in');
          }
        });
      }
    };

    if (authInfo.userId && authInfo.type !== GUEST_USER && isUserAuthorizedForPush(getUser()?.email)) checkStatus();
  }, [authInfo]);

  return null;
};
