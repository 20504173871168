import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { OrderDeliveryTab } from "./DeliveryOrderType/OrderDeliveryTab";
import styleClasses from "./ItemGrid.module.scss";
import OrderPickupTab from "./PickupOrderType/OrderPickupTab";
import { DELIVERY_ORDER_TYPE, PICK_UP_ORDER_TYPE, ROUTE_CHECKOUT } from "../../constants";
import { useAppSelector } from "../../redux/store/store";
import { IItemPathToRoute } from "../../models/item.model";
import { useLocation } from "react-router-dom";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

//refactored code
interface IOrderTypeProps {
  itemPath?: IItemPathToRoute;
  handleOrderTypeModal?: () => void;
  changeToDelivery?: boolean;
  handleShowDeliveryMethodModal?: (boolean) => void;
  changeToPickup?:boolean;
  handleShowPickupMethodModal?: (boolean) => void;
  editAddressOnCheckout?: any
}
const OrderType = (props: IOrderTypeProps) => {
  const { 
    itemPath, 
    handleOrderTypeModal, 
    changeToDelivery,
    handleShowDeliveryMethodModal,
    changeToPickup,
    handleShowPickupMethodModal,
    editAddressOnCheckout,
  } = props;

  const selectedOrderType = useAppSelector((state) => state.cart?.orderType || "" );
  const LocationFlow = useAppSelector(
    (state) => state.Items.LocationFlow
  );
  const [showOrderTypeGrid, setShowOrderTypeGrid] = useState<boolean>(true);
  const [isNearestStoreAvailable, setIsNearestStoreAvailable] = useState<boolean>(true);
  const [showItemDeliveryDateTime , setShowItemDeliveryDateTime] = useState(false);
  const [showItemPickupDateTime , setShowItemPickupDateTime] = useState(false);
  const [ saveSelectedOrderType , setSelectedOrderType] =useState<string>(selectedOrderType || PICK_UP_ORDER_TYPE);
  const isItMobile = useCheckMobileScreen()
  const location = useLocation();

  const handleOrderType = (orderType: string) => {
    setSelectedOrderType(orderType)
  };

  const showItemDeliveryDateTimeModalMethod = () => {
    handleShowDeliveryMethodModal(true)
  }
  const showItemPickupDateTimeModalMethod = () => {
    handleShowPickupMethodModal(true)
  }
  useEffect(()=>{
    if(changeToDelivery){
      setShowItemDeliveryDateTime(true)
      handleOrderType(DELIVERY_ORDER_TYPE)
    }
  }, [changeToDelivery])

  useEffect(()=>{
    if(changeToPickup){
      setShowItemPickupDateTime(true)
      handleOrderType(PICK_UP_ORDER_TYPE)
    }
  }, [changeToPickup])

  const getDefaultKey = () => {
    return changeToPickup ? PICK_UP_ORDER_TYPE : changeToDelivery ? DELIVERY_ORDER_TYPE : saveSelectedOrderType
  }
  return (
    <div
      className={`${styleClasses.order_pickup_wrapper} order-pickup-wrapper`}
    >
      {!showOrderTypeGrid && (
          <h5 className="f-s22 font-Cls text-center fw-normal pb-3 text-capitalize">
            Select Your Order Type
          </h5>
      )}

        <Tab.Container defaultActiveKey={getDefaultKey()}>
          {showOrderTypeGrid && (
              <>
                <Nav variant="pills" className="d-flex justify-content-center">
                  <Nav.Item>
                    <Nav.Link
                        eventKey={PICK_UP_ORDER_TYPE}
                        className={`${styleClasses.order_type} mx-3 px-4 ${editAddressOnCheckout && styleClasses.nav_disabled}`}
                        onClick={() => handleOrderType(PICK_UP_ORDER_TYPE)}
                        disabled={editAddressOnCheckout}
                    >
                      Pick-Up
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className={!isNearestStoreAvailable ? "too-far-Location" : "" }>
                    <Nav.Link
                        eventKey={DELIVERY_ORDER_TYPE}
                        className={`${styleClasses.order_type} mx-2 `}
                        onClick={() => handleOrderType(DELIVERY_ORDER_TYPE)}
                    >
                      Delivery
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {
                  editAddressOnCheckout ? <div className="clr-dark-red f-s14 text-center pt-2">You can't change order type here.</div> : null
                }
              </>
          )}
          <Tab.Content>
            <Tab.Pane eventKey={PICK_UP_ORDER_TYPE}>
              <OrderPickupTab
              itemPath={itemPath ?? LocationFlow?.intendedPath} 
              handleOrderTypeModal={handleOrderTypeModal}  
              showItemPickupDateTime={showItemPickupDateTime}
              handleShowPickupMethodModal={showItemPickupDateTimeModalMethod}
              saveSelectedOrderType={saveSelectedOrderType}
              isItMobile={isItMobile}
              selectedOrderType={selectedOrderType}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={DELIVERY_ORDER_TYPE}>
              {
                showOrderTypeGrid ?
                    <p className="text-center my-3 f-s14 pb-2">
                      Some items cannot be delivered.
                    </p> : null
              }
              <OrderDeliveryTab
                  itemPath={itemPath ?? LocationFlow?.intendedPath} 
                  setShowOrderTypeGrid={setShowOrderTypeGrid}
                  handleOrderTypeModal={handleOrderTypeModal}
                  isNearestStoreAvailable={isNearestStoreAvailable}
                  setIsNearestStoreAvailable={setIsNearestStoreAvailable}
                  showItemDeliveryDateTime={showItemDeliveryDateTime}
                  handleShowDeliveryMethodModal={showItemDeliveryDateTimeModalMethod}
                  saveSelectedOrderType={saveSelectedOrderType}
                  handleOrderType={handleOrderType}
                  isItMobile={isItMobile}
                  isCheckout={location.pathname.includes(ROUTE_CHECKOUT)}
                  showOrderTypeGrid={showOrderTypeGrid}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
    </div>
  );
};

export default OrderType;
