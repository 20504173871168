import { logCustomEvent, changeUser, initialize ,logPurchase, openSession, isPushSupported, requestPushPermission, requestImmediateDataFlush } from "@braze/web-sdk";
import * as CONSTANT from "../constants";
import { toFixedNumber } from "../priceCalculation/helper";
import { getSelectedStore, getUser } from "./helperMethods";
import { PickUpMethods } from "./tracking";
import { IUserInfo } from "../models/brazePayloads.model";
import { IApplyOfferOnCart, IUpsellClickPayloadForBraze } from "../models/order.model";
import { orderMicroService } from "../services";
import { isUserAuthorizedForPush } from "../components/utils";

export const brazeInititalize = () => {
  if (Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) {
    let sessionTimeout;
    initialize(process.env.REACT_APP_BRAZE_API_KEY, {
      baseUrl: process.env.REACT_APP_BRAZE_BASE_URL,
      enableLogging: Boolean(
        process.env.REACT_APP_BRAZE_ENABLE_CONSOLE_LOGGING
      ),
      serviceWorkerLocation: '/service-worker.js',
      allowUserSuppliedJavascript: true,
    });

    sessionTimeout && clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      localStorage.removeItem(
        `ab.storage.messagingSessionStart.${process.env.REACT_APP_BRAZE_API_KEY}`
      );
      openSession();
      requestImmediateDataFlush();
      console.log('Braze session cleared & refreshed.');
    }, 2000);

    // Register push capabilities after initialization
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          );
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }
};
/**
 * Check if push notifications are supported and enabled
 * This combines Braze's isPushSupported with browser permission check
 */
export const checkPushStatus = () => {
    // First check if push is supported by the browser
    const pushSupported = isPushSupported();
    
    // Then check if permission is granted
    const isPushEnabled = pushSupported && Notification.permission === "granted";
    
    return {
      pushSupported,
      isPushEnabled
    };
  };
  
  /**
   * Show a soft push prompt to the user
   * Call this before showing the browser's native permission dialog
   */
  export const showSoftPushPrompt = (onUserResponse) => {

    return new Promise((resolve) => {
      const user = getUser();
      if (!isUserAuthorizedForPush(user?.email)) {
        console.log("User not authorized for push notifications:", user?.email);
        if (onUserResponse) onUserResponse(false);
        resolve(false);
        return;
      }
      // Create modal elements
      const modalOverlay = document.createElement('div');
      modalOverlay.className = 'modal-overlay';
      modalOverlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      `;
  
      const modalContent = document.createElement('div');
      modalContent.className = 'modal-content';
      modalContent.style.cssText = `
        background-color: white;
        border-radius: 12px;
        padding: 24px;
        max-width: 400px;
        width: 90%;
        text-align: center;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      `;
  
      // Add notification icon
      const notificationIcon = document.createElement('div');
      notificationIcon.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
      `;
      notificationIcon.style.cssText = `
        margin-bottom: 16px;
        color: #262626;
      `;
  
      // Add title and description
      const title = document.createElement('h2');
      title.textContent = 'Turn on Notifications';
      title.style.cssText = `
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      `;
  
      const description = document.createElement('p');
      description.textContent = 'Never miss a tasty update! Get notified about exclusive deals and fresh offers.';
      description.style.cssText = `
        font-size: 14px;
        color: #737373;
        margin-bottom: 24px;
      `;
  
      // Add buttons
      const buttonContainer = document.createElement('div');
      buttonContainer.style.cssText = `
        display: flex;
        flex-direction: column;
        gap: 12px;
      `;
  
      const allowButton = document.createElement('button');
      allowButton.textContent = 'Allow';
      allowButton.style.cssText = `
        background-color: #0956A8;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 12px;
        font-weight: 600;
        cursor: pointer;
      `;
  
      const notNowButton = document.createElement('button');
      notNowButton.textContent = 'Not Now';
      notNowButton.style.cssText = `
        background-color: transparent;
        color: #262626;
        border: none;
        padding: 12px;
        font-weight: 500;
        cursor: pointer;
      `;
  
      // Add event listeners
      allowButton.addEventListener('click', () => {
        // Remove modal
        document.body.removeChild(modalOverlay);
        localStorage.setItem('notifications', "false")
        
        if(/Edg/.test(navigator.userAgent)){
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                  console.log("The user accepted to receive notifications");
                  if (onUserResponse) onUserResponse(permission);
                  resolve(permission);
                }
              });  
        }else{
            requestBrazePushPermission()
              .then(granted => {
                if (granted) {
                  console.log("Push permission granted after soft prompt");
                } else {
                  console.log("Push permission denied after soft prompt");
                }
                if (onUserResponse) onUserResponse(granted);
                resolve(granted);
              });
        }
      });
  
      notNowButton.addEventListener('click', () => {
        // Remove modal
        document.body.removeChild(modalOverlay);
        localStorage.setItem('notifications', "false")
        
        console.log("Soft push prompt declined");
        if (onUserResponse) onUserResponse(false);
        resolve(false);
      });
  
      // Assemble and append modal to body
      buttonContainer.appendChild(allowButton);
      buttonContainer.appendChild(notNowButton);
      
      modalContent.appendChild(notificationIcon);
      modalContent.appendChild(title);
      modalContent.appendChild(description);
      modalContent.appendChild(buttonContainer);
      
      modalOverlay.appendChild(modalContent);
      document.body.appendChild(modalOverlay);
    });
  };
  
  /**
   * Request push permission using Braze's API
   */
  export const requestBrazePushPermission = () => {
    return new Promise((resolve) => {
      if (!isPushSupported()) {
        console.log("Push notifications are not supported in this browser");
        resolve(false);
        return;
      }
  
      // Use Braze's push permission API
      // This method handles both requesting permission and registering the push token
      requestPushPermission((granted) => {
        if (granted) {
          console.log("Push permission granted");
          
          // Force data flush to ensure token is sent to Braze
          requestImmediateDataFlush();
        } else {
          console.log("Push permission denied");
        }
        resolve(granted);
      });
    });
  };
  

export const brazeSetUser = (userId: string) => {
    if (!Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) return;
    changeUser(userId);
}
    
export const brazeLogCustomEvent = (eventName, eventProperties = null) => {
    if (!Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) return;
    if (eventProperties)
        logCustomEvent(eventName, eventProperties);
    else 
        logCustomEvent(eventName);
}


const getItemImageURL = (itemId: number) => `${CONSTANT.REACT_APP_CLOUD_FRONT}/Items/${itemId}/item-${itemId}.webp`
const getMappedObject = (item: any, isOrderCancelledEvent: boolean, isCombo: boolean = false) => {
    return {
        itemName: item.name || item.modifier_name,
        ...(!isCombo && { price: item.price }),
        ...(item.item_size && { size: item.item_size }),
        ...(!isCombo && !isOrderCancelledEvent && {
            image: getItemImageURL(item.item_id),
        }),
        quantity: item.quantity,
        ...(item.modifiers && {
        modsAdded: item?.modifiers?.map((modifier) => modifier.modifier_name),
        }),
    };
};

const getComboMappedItems = (comboItem: any, isOrderCancelledEvent: boolean) => {
    const comboMappedObject = {
        itemName: comboItem.name,
        price: comboItem.price,
        size: comboItem.item_size,
        ...(!isOrderCancelledEvent && {
            image: getItemImageURL(comboItem.item_id),
        }),
        quantity: comboItem.quantity,
        items: comboItem.modifiers.map(modifier_item => getMappedObject(modifier_item, isOrderCancelledEvent, true))
    }
    return comboMappedObject;
}

const getMappedItems = (items: any[], isOrderCancelledEvent: boolean = false) => {
    if (!items?.length) return [];

    const mappedItems = [];

    items?.forEach(item => {
        if (
            [CONSTANT.TRY_TO_COMBO_ITEM, CONSTANT.KIDS_COMBO_ITEM].includes(item.combo_type) ||
            item.isKidsCombo ||
            item.isTry2Combo ||
            item.is_kids_combo ||
            item.is_try_2_combo
            )
            mappedItems.push(getComboMappedItems(item, isOrderCancelledEvent))
        else 
            mappedItems.push(getMappedObject(item, isOrderCancelledEvent))
    });
    return mappedItems;
}

const getAccountType = (userType: string) =>
  userType === CONSTANT.GUEST_USER
    ? CONSTANT.GUEST_USER
    : CONSTANT.LOYALTY_USER;

const getIsLoyaltyRedeemed = (orderItems) => {
    return !!orderItems?.find(item => item.discount);
}

export const brazeLogCustomEventLoyaltySignIn = (isItMobile: boolean, ) => {
    const user = getUser()
    const loyaltySignInPayload = {
        CzId: user.id,
        email: user.email,
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
    }
    brazeLogCustomEvent(CONSTANT.LOYALTY_SIGN_IN, loyaltySignInPayload)
}

export const brazeLogCustomEventForBackendLogging = async (eventName, eventProperties = null, orderId) => {
    // Check if Braze tracking is enabled
    if (!Boolean(process.env.REACT_APP_ENABLE_BRAZE_TRACKING)) return;

    let responseStatus;
    
    try {
        // Log the event to Braze and get the response status
        if (eventProperties) {
            responseStatus = await logCustomEvent(eventName, eventProperties);
        } else {
            responseStatus = await logCustomEvent(eventName);
        }
        // If the response has a status, use it; otherwise, default to 500 (Internal Server Error)
        const status = responseStatus ? 'success' :  'fail';
    
        const payload = {
            "event_name":eventName,
            "event_trigger": status,
            "event_payload": eventProperties
        }

        orderMicroService.logBrazeEvent(orderId, payload);
    } catch (error) {
    
        const payload = {
            "customer_id":"117967920",
            "event_name":eventName,
            "event_trigger": 'error',
            "event_payload": eventProperties
        }

      orderMicroService.logBrazeEvent(orderId, payload);
    }
};

export const brazeLogCustomEventOrderPlaced = (orderId, order: any, isItMobile: boolean) => {
    const promiseTime = order?.promise_time?.split(" ")
    const user = getUser()
    const categoriesOrdered = order?.items?.map(item => item.category_name)
    const pickUpMethod = PickUpMethods[order?.order_types?.id]
    const orderPlacedPayload = {
        firstName: order?.customer?.first_name, 
        lastName: order?.customer?.last_name, 
        contact: order?.customer?.phone, 
        email: order?.customer?.email, 
        storeId: order?.location?.id, 
        storeName: order?.location?.name, 
        orderType: order?.order_types?.name, 
        orderDate: promiseTime?.[0],
        orderTime: promiseTime?.[1],
        zipCode: order?.customer?.address?.zipcode || order?.location?.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        isTipGiven: Boolean(order?.tip),
        isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(order?.items),
        subtotal: order?.sub_total,
        tax: order?.tax,
        discount: order?.discount,
        tipAmount: order?.tip,
        ...(pickUpMethod === CONSTANT.DELIVERY && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: order?.total_price,
    }

    // brazeLogCustomEvent(CONSTANT.ORDER_PLACED, orderPlacedPayload)
    brazeLogCustomEventForBackendLogging(CONSTANT.ORDER_PLACED, orderPlacedPayload, orderId)

}
export const brazeLogCustomEventFavoriteOrder = (order: any,isItMobile:boolean) => {

    const orderPlacedPayload = {
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB,
        favoriteOrderName: order.favorite_order_name || order.order_name,
        categoriesOrdered: order.categories_ordered,
        items: getMappedItems(order?.items),
    }


    brazeLogCustomEvent(CONSTANT.SAVE_FAVORITE_ORDER, orderPlacedPayload)
}
export const brazeLogCustomEventReOrder = (order: any, isFavorite:boolean=false,isItMobile:boolean) => {
    
    const orderPlacedPayload = {
        orderPlatform:isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB,
        categoriesOrdered: order.categories_ordered,
        items: getMappedItems(order?.items),
        ...(order.favorite_order_name && {favoriteOrderName: order.favorite_order_name})
        
    }
    brazeLogCustomEvent(isFavorite ? CONSTANT.REORDER_CLICKED_FROM_FAVORITE_ORDERS : CONSTANT.REORDER_CLICKED_FROM_RECENT_ORDERS, orderPlacedPayload)
}



export const brazeLogCustomEventOrderCancelled = (order: any, isItMobile: boolean) => {
    const promiseTime = order?.promise_time?.split(" ")
    const user = getUser()
    const categoriesOrdered = order?.items?.map(item => item.category_name)
    const pickUpMethod = PickUpMethods[order?.order_types?.id]
    const orderCancelledPayload = {
        firstName: order?.customer?.first_name, 
        lastName: order?.customer?.last_name, 
        contact: order?.customer?.phone, 
        email: order?.customer?.email, 
        storeId: order?.store_details?.id, 
        storeName: order?.store_details?.name, 
        orderType: order?.order_types?.name, 
        orderDate: promiseTime?.[0],
        orderTime: promiseTime?.[1],
        zipCode: order?.customer?.zipcode || order?.store_details?.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        isTipGiven: Boolean(order?.tip),
        isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(order?.items, true),
        subtotal: order?.sub_total,
        tax: order?.tax,
        discount: order?.discount,
        tipAmount: order?.tip,
        ...(pickUpMethod === CONSTANT.DELIVERY && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: order?.total_price,
    }
    brazeLogCustomEvent(CONSTANT.ORDER_CANCELLED, orderCancelledPayload)
}

interface IOrderAmount {
    discount: number;
    tax: number;
    subTotal: number;
    total: number;
}
export const brazeLogCustomEventCheckoutStarted = (
    cart: any, 
    orderAmount: IOrderAmount, 
    isItMobile: boolean, 
    slotDetails: any,
) => {
    const user = getUser()
    const selectedStore = getSelectedStore()
    const categoriesOrdered = cart?.items?.map(item => item.category_name)
    const checkoutStartedPayload = {
        firstName: user.first_name, 
        lastName: user?.last_name, 
        contact: user?.phone, 
        email: user?.email, 
        storeId: selectedStore.id, 
        storeName: selectedStore?.name, 
        orderType: slotDetails?.pickupMethod || cart?.orderType,
        cartId: cart?.orderId,
        isAsapOrder: slotDetails?.isAsapOrder,
        orderDate: slotDetails?.date,
        orderTime: slotDetails?.isAsapOrder ? slotDetails?.asapMinutes : slotDetails?.time,
        zipCode: cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE ? cart?.orderDetails?.delivery_address?.zipcode : selectedStore.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB,      
        discount: orderAmount?.discount,

        offerName: cart?.offerName,

        rootOfferId: cart?.root_offer_id,

        userOfferId: cart?.user_offer_id,
        accountType: getAccountType(user?.type), 
        categoriesOrdered: categoriesOrdered,
        items: getMappedItems(cart?.items),
        subtotal: orderAmount?.subTotal,
        tax: orderAmount?.tax,
        ...( cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE && {
            deliveryFee: CONSTANT.DELIVERY_FEE,
            serviceFee: CONSTANT.SERVICE_FEE,
        }),
        total: orderAmount?.total,
    }
    brazeLogCustomEvent(CONSTANT.CHECKOUT_STARTED, checkoutStartedPayload)
}

export const brazeLogCustomEventAddedToCart = (cart: any, cartId: any, itemAdded: any,isItMobile: boolean) => {
    const user = getUser()
    const selectedStore = getSelectedStore()
    const addedToCartPayload = {
        storeId: selectedStore.id, 
        storeName: selectedStore?.name, 
        orderType: cart?.orderType,
        cartId: cart?.orderId || cartId,
        zipCode: cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE ? cart?.orderDetails?.delivery_address?.zipcode : selectedStore.zip, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        items: getMappedItems(itemAdded),
        total: toFixedNumber(itemAdded[0]?.display_price * itemAdded[0]?.quantity),
    }
    brazeLogCustomEvent(CONSTANT.ADDED_TO_CART, addedToCartPayload)
}

export const brazeLogCustomEventViewPage = (pageName: string, isItMobile: boolean) => {
    const user = getUser()
    const viewPagePayload = {
        pageName,
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
    }
    brazeLogCustomEvent(CONSTANT.VIEW_PAGE, viewPagePayload);
}

export const brazeLogCustomEventLoyaltyAccountCreated = (userInfo) => {
    let dateOfBirth = "";
    if (userInfo.birthday) {
        const splitBirthday = userInfo.birthday.split("-");
        dateOfBirth = `${splitBirthday[1]}/${splitBirthday[2]}/${splitBirthday[0]}`;
    }
    let favoriteLocations = [];
    if (userInfo?.favorite_location)
        favoriteLocations = userInfo?.favorite_location?.map(location => location.label);
    const loyaltyAccountCreatedPayload = {
        firstName: userInfo.first_name, 
        lastName: userInfo.last_name, 
        contact: userInfo.phone || "", 
        email: userInfo.email, 
        dateOfBirth, 
        favoriteLocations,
    }      
    brazeLogCustomEvent(CONSTANT.LOYALTY_ACCOUNT_CREATED, loyaltyAccountCreatedPayload)
}

export const brazeLogCustomEventAddToFavorites = (item: any, isItMobile: boolean) => {
    const user = getUser();
    const addToFavoritesPayload ={
        itemName: item.name,
        itemTag: item.tags.map(tag => tag.name),
        itemDescription: item.description,
        itemCategory: item.category_name || "",
        price: item.price,
        image: getItemImageURL(item.id),
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
    }
    brazeLogCustomEvent(CONSTANT.ADD_TO_FAVORITES, addToFavoritesPayload);
}

export const brazeLogCustomEventFormSubmission = (formName: string, isItMobile: boolean, formFields: any) => {
    const user = getUser();
    const formSubmissionPayload = {
        formName, 
        orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        accountType: getAccountType(user?.type), 
        data: {
            ...formFields,
        }
    }
    brazeLogCustomEvent(CONSTANT.FORM_SUBMISSION, formSubmissionPayload);
}

const getMappedPurchaseItems = (data: any, isItMobile?:boolean) => {
    if (!data?.items?.length) return [];
    data?.items?.forEach((item: any) => {
      if (
        [CONSTANT.TRY_TO_COMBO_ITEM,CONSTANT.KIDS_COMBO_ITEM].includes(item?.combo_type) ||
        item?.isKidsCombo ||
        item?.isTry2Combo ||
        item?.is_kids_combo ||
        item?.is_try_2_combo
      )
        getComboMappedPurchaseItems(item, data,isItMobile);
      else getMappedPurchaseObject(item, data,null,isItMobile);
    });
  };
  
  const getMappedPurchaseObject =  (
    item: any,
    data?: any,
    from?: string,
    isItMobile?:boolean
  ) => {
    const promiseTime = data?.promise_time?.split(" ")

    const brazeObj = {
        orderId: data?.id,
      PosId: item?.brink_id || null,
      itemName: item?.name || item?.modifier_name || '',
      category: item?.category_name || '',
      size: item?.size || '',
      orderDate: promiseTime?.[0],
      orderTime: promiseTime?.[1],
      orderType: data?.order_types?.name,
      grossPrice: (item?.gross_price || 0.0).toFixed(2),
      price:item?.quantity * item?.price,
      storeId: data?.location?.id,
      storeName: data?.location?.name,
      image: getItemImageURL(item?.item_id),
      orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 
        modsAdded: item?.modifiers_added?.length ? item?.modifiers_added : [],
    };
    if (from) {
      return brazeObj;
    }
    logPurchase(
      item?.name,
      (item?.gross_price *  item?.quantity),
      'USD',
      item?.quantity,
      brazeObj,
    );
  };
  
  const getComboMappedPurchaseItems =  (comboItem: any, data: any,isItMobile?:boolean) => {
    const promiseTime = data?.promise_time?.split(" ")
   
    const comboMappedObject = {
        orderId: data?.id,
      PosId: comboItem?.brink_id || null,
      itemName: comboItem?.name || '',
      category: comboItem?.category_name || '',
      size: comboItem?.size || '',
      orderDate: promiseTime?.[0],
      orderTime: promiseTime?.[1],
      orderType: data?.order_types?.name,
      grossPrice:(comboItem?.quantity* comboItem?.gross_price || 0.0).toFixed(2),
      price:  comboItem?.price,
      storeId: data?.location?.id,
      storeName: data?.location?.name,
      image: getItemImageURL(comboItem?.item_id),
      itemId: comboItem?.item_id,
      orderPlatform: isItMobile ? CONSTANT.SOURCE_MOBILE_WEB: CONSTANT.SOURCE_WEB, 

      modsAdded: comboItem?.modifiers_added?.length ? comboItem?.modifiers_added : [],
      items: comboItem?.modifiers?.map(modifierItem => {
         return getMappedPurchaseObject(modifierItem, data, 'combo',isItMobile);
        }) || [],
    };


    logPurchase(
        comboItem?.name,
        (comboItem?.gross_price * comboItem?.quantity),
        'USD',
        comboItem?.quantity,
        comboMappedObject,
      );
  
}
  
export const brazeLogOrderPlace = (orderedItems: any, isItMobile?: boolean) => { 
    getMappedPurchaseItems(orderedItems,isItMobile)
}
  
export const brazeLogEventSubscribedSMSMarketing = (userInfo: IUserInfo) => {
    const smsMarketingPayload = 
    {
        CzId: userInfo?.id,
        firstName: userInfo?.first_name,
        lastName: userInfo?.last_name, 
        contact: userInfo?.phone,
        email: userInfo?.email 
    }
    brazeLogCustomEvent(CONSTANT.SUBSCRIBE_TO_SMS_MARKETING, smsMarketingPayload);
}

export const brazeLogEventClaimPromoCode = (promo_code: string, userId: number, cartId: number) => {
    const claimPromoPayload = 
    {
        customer_id: userId,
        cart_id: cartId,
        promo_code: promo_code,
        source: CONSTANT.SOURCE_WEB
    }
    brazeLogCustomEvent(CONSTANT.CLAIM_PROMO_CODE, claimPromoPayload);
}

export const brazeLogEventOfferAdded = (offerData: IApplyOfferOnCart, userId: number, cartId: number) => {
    const offerAddedPayload = 
    {
        root_offer_id: offerData?.root_offer_id,
        user_offer_id: offerData?.user_offer_id,
        eligible_item_offer_id: offerData?.eligible_item_offer_id,
        customer_id: userId,
        cart_id: cartId,
        source: CONSTANT.SOURCE_WEB
    }
    brazeLogCustomEvent(CONSTANT.OFFER_ADDED_CART, offerAddedPayload);
}

export const brazeLogEventOnUpsellClick = (payload: IUpsellClickPayloadForBraze) => {
    brazeLogCustomEvent(CONSTANT.UPSELL_CLICK, payload);
}
