import { REACT_APP_BOWLS_ID, REACT_APP_DRINKS_ID, FULL_SIZE, HALF_SIZE, LARGE_SIZE, NO_TIME_SLOTS_PRESENT, REGULAR_SIZE, REACT_APP_SALADS_ID, REACT_APP_DESSERTS_ID, MINI_DESSERT_SIZE, REGULAR_DESSERT_SIZE } from "../constants";

export const capitalizeFirstLetter=(value)=> {
    return value.charAt(0)?.toUpperCase() + value.slice(1);
  }
export const isSizeEqual=(item,reward)=>{
  return (item.item_size?.toLowerCase()==reward.redirect_item_size?.toLowerCase())
}
export const isCartEmpty=(Cart)=> Cart.cart.itemTree && Object.keys(Cart.cart.itemTree).length == 0;
export const getExpiry_year = (values) => {
  if (Number(values.card.expiration.split("/")[1]) < 100) {
    return Number(new Date().getFullYear().toString().substring(0, 2) + values.card.expiration.split("/")[1]);
  } else {
    return values.card.expiration.split("/")[1];
  }
};
export const formatDate = (date) => {
  const currentDate = new Date();
  let dateSplit = date ? date.split('-') : ['2023', '01', '01']
  let selectedDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
  let updateDateFormat = "";
  if (
    currentDate.getDate() == selectedDate.getDate() &&
    currentDate.getDay() == selectedDate.getDay()
  ) {
    updateDateFormat =
    "Today, " +
    selectedDate.toLocaleDateString("en-US", {
      weekday: "long",
   // year: "numeric",
    month: "short",
    day: "numeric",
    });
} else {
  updateDateFormat = selectedDate.toLocaleDateString("en-US", {
    weekday: "long",
    // year: "numeric",
     month: "short",
     day: "numeric",
  });
  }
  return updateDateFormat;
};
export const formatTime = (time: string , orderDetails) => {
  if (orderDetails?.time == '' || orderDetails?.time == NO_TIME_SLOTS_PRESENT){
    return NO_TIME_SLOTS_PRESENT;
  }
  if (!orderDetails?.time?.includes("ASAP")) {
    const hoursMinsSec = orderDetails?.time?.split(":");
    time = formatAMPM(hoursMinsSec[0], hoursMinsSec[1]);
  } else {
    time = orderDetails?.time;
  }
  return time;
};
export const formatAMPM = (hours, mins) => {
        const ampm = hours >= 12 ? "pm" : "am";
        hours %= 12;
        hours = hours || 12;
        mins = mins < 10 ? `${mins}` : mins;
        hours = hours < 10 ? `0${hours}` : hours;
        const formatedTime = `${hours}:${mins} ${ampm}`;
    
        return formatedTime;
};

export const scrollOnSize = ( divRef, offset ) => {
  setTimeout(() => {
    const yOffset = offset 
    const ScrollYCordinates = divRef?.current?.getBoundingClientRect().top + window.pageYOffset+ yOffset;
    window.scrollTo({ top: ScrollYCordinates, behavior: "smooth" });
}, 250);
}
export const getSizeName = ( categoryName:string, categoryId:string) => {
  if([REACT_APP_BOWLS_ID,REACT_APP_SALADS_ID, REACT_APP_DRINKS_ID].includes(categoryId?.toString())) return LARGE_SIZE
  else if(categoryId?.toString() === REACT_APP_DESSERTS_ID) return REGULAR_DESSERT_SIZE
  else return FULL_SIZE
}

export const getSizeNameInCaseOfHalf = (categoryId:string ) => {
  if(categoryId?.toString() === REACT_APP_DRINKS_ID) return REGULAR_SIZE.title_case
  else if(categoryId?.toString() === REACT_APP_DESSERTS_ID) return MINI_DESSERT_SIZE.upper_case
  else return HALF_SIZE.title_case
}

export const itemSizeForMobile = (sizeTextFor: 'HALF' | 'FULL', categoryName:string, categoryId:string) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return getSizeNameInCaseOfHalf(categoryId);
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName, categoryId).title_case
    default:
      break;
  }
}

export const itemSizeForDesktop = (sizeTextFor: 'HALF' | 'FULL', categoryName:string, categoryId:string) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return getSizeNameInCaseOfHalf(categoryId).toUpperCase();
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName, categoryId).upper_case
    default:
      break;
  }
}

export const isZeroOnStart = (value) => {
  const valueArray = value && value?.split('');
  return valueArray?.length > 0 && valueArray[0] <= 0
}

export const delayer = (func, time = 50) => {
  let isRun = false;
  return () => {
    if (isRun === false) {
      func();
      isRun = true;
      setTimeout(() => {
        isRun = false;
      }, time);
    }
  };
};
export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Parse the comma-separated emails from environment variable
const getAuthorizedPushEmails = () => {
  const emailsString = process.env.REACT_APP_AUTHORIZED_PUSH_EMAILS || '';
  return emailsString.split(',').map(email => email.trim()).filter(email => email);
};

// Function to check if a user is authorized for push notifications
export const isUserAuthorizedForPush = (userEmail) => {
  if (!userEmail) return false;
  
  const authorizedEmails = getAuthorizedPushEmails();
  const normalizedEmail = userEmail.toLowerCase().trim();
  return authorizedEmails.some(email => email.toLowerCase() === normalizedEmail);
};

export const checkIfPaymentIsAttachedOrNot = (paymentDetails) => {
  return paymentDetails.some(
    (payment) =>
      payment?.card?.id ||
      payment?.gift_card?.id ||
      payment?.house_account?.name ||
      payment?.in_store?.id ||
      payment?.invoice?.id,
  );
};
export const displayItemSize = (item: any) => {
  const item_size = item?.item_size ?? item?.size;
  const size : string = item_size === FULL_SIZE.id || item_size === REGULAR_DESSERT_SIZE.id
    ? getSizeName(item?.category_name, item?.category_id?.toString()).upper_case :  
  getSizeNameInCaseOfHalf(item?.category_id?.toString());
  return size;
}
