import React from "react";
import { checkIfPaymentIsAttachedOrNot } from "../../../components/utils";

interface IPaymentProps {
  LineHide?: any;
  MobileHide?: any;
  titleStyle: string;
  paymentDetails: any[];
  tableEndSpacing?: string;
}
const PaymentMethod: React.FunctionComponent<IPaymentProps> = ({
  LineHide,
  MobileHide,
  titleStyle,
  paymentDetails,
  tableEndSpacing,
}) => {
  const isDigital = paymentDetails[0]?.card?.is_digital_payment ? true : false;
  const isProcessedPresent = paymentDetails.some(
    (paymentMethod) => paymentMethod.is_processed
  );

  if(!checkIfPaymentIsAttachedOrNot(paymentDetails)) return null;

  return (
    <>
      <div
        className={`${MobileHide} d-flex justify-content-between align-items-center pb-4 pb-md-0`}
      >
        <div className="w-100">
          <h4
            className={`f-sm-s16 f-s20 clr-dark-grey text-start h-22 pt-3 ${titleStyle}`}
          >
            Payment Information
          </h4>
          <div className="bot">
            <table className="w-100">
              <tr>
                <th className="text-start pe-2">Name</th>
                {!isDigital && <th className="text-start px-2">Card</th>}
                {isProcessedPresent && (
                  <th className={`text-start f-sm-s14 px-2 ${tableEndSpacing}`}>
                    Date
                  </th>
                )}

                <th className={`text-start f-sm-s14 px-2 ${tableEndSpacing}`}>
                  Status
                </th>
                <th className={`text-end ${tableEndSpacing} ps-2`}>Amount</th>
              </tr>
              {paymentDetails.map((paymentMethod, index) => {
                let customerName = "";
                let last4 = "";
                // let status = "";
                if (
                  paymentMethod?.card?.id ||
                  paymentMethod?.card?.is_digital_payment
                ) {
                  customerName = paymentMethod?.card?.card_name;
                  last4 = paymentMethod?.card?.last4;
                  // status = paymentMethod?.card?.status;
                } else if (paymentMethod.gift_card) {
                  customerName = "Gift Card";
                  last4 = paymentMethod?.gift_card?.gift_card_no;
                  // status = paymentMethod?.gift_card?.status;
                } else return null;
                return (
                  <tr key={index}>
                    <td className="text-start pe-2">{customerName}</td>
                    {!isDigital && (
                      <td className="text-start px-2">
                        {last4 ? `Ending With ...${last4}` : "-"}
                      </td>
                    )}
                    {isProcessedPresent && (
                      <td className="px-2">{paymentMethod?.processed_at}</td>
                    )}
                    <td className="px-2 text-start">
                      {paymentMethod?.payment_status}
                    </td>
                    <td className={`text-end ${tableEndSpacing} ps-2`}>
                      ${paymentMethod.amount}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <hr className={`${LineHide} custom_hr_sty d-md-block d-none`} />
    </>
  );
};

export default PaymentMethod;
